import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import localStorageService from "../../../utils/helpers/services/localStorageService";
import { IDENTITY_SERVICES_PRODUCTS } from '../../../utils/helpers/constants/localStorageKeys';


import './SubscriptionCard.css';


const SubscriptionCard = ({ props }) => {
    const dolars = props.subscriptionPrice
    const cents = props.subscriptionPrice;
    const history = useHistory();

    const [currentPath, setCurrentPath] = useState('');

    useEffect(() => {
        setCurrentPath(window.location.pathname);
    }, []);

    const handleGoToCheckout = () => {
        localStorageService.saveProduct(IDENTITY_SERVICES_PRODUCTS.CARD_PRODUCT_SUBSCRIPTON, props);
        localStorageService.deleteProduct('sectionName');
        sessionStorage.setItem('sectionName', 'price');
        history.push('/checkout');
    }

    const cardHeigth = currentPath === '/checkout' ? '25em' : 'auto';

    return (

        <div className="SubscriptionCard__container" style={{height : cardHeigth}}>
            <div className="SubscriptionCard__card-container">
                <div className="SubscriptionCard__card-title">
                    <h1>{props.subscriptionType}</h1>
                </div>
                {
                    (currentPath !== '/checkout' || currentPath === '/checkout' ) && <div className="SubscriptionCard__card-trial-info" >
                        {/* <p>{props.subscriptionTrialInfo} <b>${props.subscriptionTrialPrice}</b></p> */}
                        <p>{props.subscriptionTrialInfo} </p>
                    </div>
                } 
                <div className="SubscriptionCard__card-price">
                    <p>
                        <span className="SubscriptionCard__dolar-price">{`$${dolars.split('.')[0]}`}</span><span className="SubscriptionCard__cents-price">{`.${cents.split('.')[1]}`}</span>
                    </p>
                </div>

                <div className="SubscriptionCard__card-description">
                    <p>
                        {props.subscriptionDescription}
                    </p>
                </div>

                {( currentPath !=='/checkout') && <div className="SubscriptionCard__button-container">
                    <div className="SubscriptionCard__button">
                        <button onClick={(e) => { e.preventDefault(); handleGoToCheckout(); }}>
                            BUY
                        </button>
                    </div>
                </div>

                }
                <div className="SubscriptionCard__subscription-definition-container">
                    {(currentPath === '/price' || currentPath === "/") && <p>
                        Please have a look at the <Link to="/terms-of-service">terms and conditions</Link> and
                        contact us if you have any questions or concerns.
                        Your monthly membership fee will be automatically billed
                        to your designated billing source at the end of the period.
                        If you wish to <Link to="/cancel-service">cancel the service</Link>, remember to notify us
                        of your decision by calling or completing the cancellation
                        form.
                    </p>}
                </div>
            </div>
        </div>
    );
};

export default SubscriptionCard;