import React, { useState} from 'react';
import { NavLink } from 'react-router-dom';

import './NavegationLinks.css';
import CallUsButton from '../../../UIElements/CallUsButton';

const NavLinks = props => {
  
  let sectionName = sessionStorage.getItem('sectionName') ? sessionStorage.getItem('sectionName') : 'home';

  const [activeLink, setActiveLink] = useState(sectionName);
  const handleHomeClick = ( sectionId ) => {
    console.log(sectionId)
    const sectionElement = document.getElementById(sectionId);
    console.log(sectionElement)
    sessionStorage.setItem('sectionName', sectionId);
    if (sectionElement) {
        const windowHeight = window.innerHeight;
        const docScrollTop = window.scrollY || document.documentElement.scrollTop;
        const sectionTop = sectionElement.getBoundingClientRect().top + docScrollTop;
        const centeredPosition = sectionTop - (windowHeight / 2);
        const scrollToPosition = centeredPosition + 310; 
        window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
    } else {
        console.log("The section does not exist");
    }
    setActiveLink(sectionId);
  };

  return (
    <React.Fragment>
    <ul className="nav-links" >
      <li>
        <NavLink  to="/"  onClick={() => handleHomeClick('home')}className={activeLink === 'home' ? 'active-link' : ''} >HOME</NavLink> 
      </li>

      <li>
        <NavLink to="/" onClick={() => handleHomeClick('about-us')} className={activeLink === 'about-us' ? 'active-link' : ''}>ABOUT US</NavLink> 
      
      </li>

      <li>
         <NavLink to="/" onClick={() => handleHomeClick('price')}  className={activeLink === 'price' ? 'active-link' : ''} >PRICE</NavLink>
      </li>

      <li>
        <NavLink to="/" onClick={() => handleHomeClick('contact-us')}  className={activeLink === 'contact-us' ? 'active-link' : ''} >CONTACT</NavLink>
      </li>

      <li>
        <a href="/cancel-service" onClick={() => handleHomeClick('cancel-service')} className={activeLink === 'cancel-service' ? 'active-link' : ''}>CANCEL</a>
      </li>

       <li className='NavLinks__call-us-button-link' >
        <CallUsButton
        phonenumber="CALL NOW"
        />
      </li> 

    </ul>

    </React.Fragment>
  );
};

export default NavLinks;
