import React from "react";
import './SubscriptionPlan.css';


import SubscriptionCard from '../../../shared/components/SubscriptionCard/SubscriptionCard';
import SubscriptionDescription from '../../../shared/components/SubscriptionDescription/SubscriptionDescription';
import womanReadingImg from '../../../utils/assets/price/woman-reading-a-magazine.png';







const SubscriptionPlan = props => {

    const subscriptionCardInformation = {
        subscriptionType: "Join Us",
        subscriptionPrice: "29.95",
        subscriptionTrialInfo: "Our product",
        subscriptionTrialPrice :'3.29',
        subscriptionDescription: "Monthly",
        subscriptionButtonText: "Buy",
        }

    const subscriptionInformation = {
        img :womanReadingImg,
        imgDescription : "Cuddle dog",
        title : "Are you ready to have the best magazines sent to you monthly?",
    }

  
        
    return (
        <section className="SubscriptionPlan__main-section-container" id="price">
            <div className="SubscriptionPlan__subscription-plans-container">
                <div className="SubscriptionPlan__subscription-description-component-container">
                    <SubscriptionDescription props={subscriptionInformation} />
                </div>
                <div className="SubscriptionPlan__subscription-card-component-container">
                    <SubscriptionCard props={subscriptionCardInformation} />
                </div>
            </div>

        </section>
    );
};

export default SubscriptionPlan;