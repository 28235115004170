import React from "react";

import './ReimbursementGuidelines.css';

import ContactCard from '../../../shared/components/ContactCard/ContactCard';


const ReimbursementGuidelines = props => {
 
    return (
        <>
        <section className="ReimbursementGuidelines__main-section-container">
            <div>
                <h1 className="text-center ReimbursementGuidelines__main-title"> How to get reimbursed</h1>
            </div>
            <div className="ReimbursementGuidelines__section-description">
                <p>
                At Magazines Lovers , <b>we want to ensure that you are 100% happy with your magazines.</b> If you have any technical or sales queries, do not hesitate to
                contact us. However, if you feel the magazine(s) you purchased are not the best fit for your requirements and you attempted to resolve issues with our 
                support staff, we want to make things right. We’d love to know where things went wrong, or how we can improve. 
                </p>
                <br/>
                <p>

                Follow the steps below for a full, no-hassle, <b>refund within 30 days of your date of purchase.</b> Please include your order number and why you’d like a
                refund, so we can issue a refund as quickly as possible. We make every attempt to process the refund as quickly as possible. <b>Our payment processor or
                your financial institution can take up to 1-5 days for the refund to reflect in your bank account/card.
                </b> 
                </p>
                <br/>
                <p className="text-decoration-underline">Requesting a Refund</p>
              
                    <ol>
                        <li>Contact Support by emailing help@magazineslovers.com or calling us at (888) 474-3416.</li>
                        <li>If you need to send an email, please clearly state in the subject line that you are requesting a refund and include your order identification number.</li>
                        <li>Include a detailed description of the reason for your refund in the email. If you are requesting a refund for only part of your order, please specify the products for which you are requesting a refund. This information helps us improve our products.</li>
                        <li>Once the request has been submitted, you will receive an automatic response notifying you that your request has been successfully submitted. The support team will contact you as soon as possible. </li>
                        <li>Once your request has been reviewed, Support will contact you to confirm the refund process and let you know how to proceed. </li>
                    </ol>
            
            </div>
        </section>
        
        <section className="ReimbursementGuidelines__customer-service-container">
            <div className="ReimbursementGuidelines__customer-service">
                <ContactCard/>
            </div>
        </section>

    </>
    );
};

export default ReimbursementGuidelines;